.color-selector {
  width: 60px;
  height: 60px;
  list-style-type: none;
  display: inline-block;
  border-radius: 30px;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
  }
}

.color-selected {
  border: 2px solid black;
  border-radius: 35px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: -5px;
  padding: 20px;
  left: -5px;
}

.color-picker {
  margin-top: -20px;

  h2 {
    display: block;
    margin-bottom: 0;
    padding-top: 12px;
    vertical-align: super;
  }

  .color-list {
    display: inline-flex;
    vertical-align: middle;
    list-style-type: none;
    overflow-x: auto;
    width: 100%;
    padding-left: 40px;
    padding-top: 22px;

    li {
      position: relative;
      margin-left: 35px;

      p {
        margin-top: 5px;
        font-size: 16px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .color-picker {
  
    h2 {
      display: inline-block;
    }

    .color-list {
      width: 620px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1245px) {
  .color-picker {
    max-width: calc(100% - 340px);
  }
 }
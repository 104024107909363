.carousel-root {
  max-width: 743px;
  max-height: 464px;
  width: 100%;
  height: 100%;
  margin-top: 30px;

  .carousel .slide img {
    max-width: 743px;
    max-height: 464px;
    width: 100%;
    height: 100%;
  }

  .control-arrow.control-prev::before,
  .control-arrow.control-next::before {
    content: none;
  }

  .carousel.carousel-slider .control-arrow {
    top: calc(50% - 18px);
    width: 35px;
    height: 36px;
    opacity: 1;
  }

  .control-arrow.control-prev,
  .control-arrow.control-next {
    background-repeat: no-repeat;

    &:hover {
      background-repeat: no-repeat;
      background: 0;
      opacity: 1;
    }
  }

  .control-arrow.control-prev {
    background-image: url('../icons/leftArrowGalleryIcon.svg');
    left: 20px;
  }

  .control-arrow.control-next {
    background-image: url('../icons/rightArrowGalleryIcon.svg');
    right: 20px;
  }

  .control-arrow.control-prev:hover {
    background-image: url('../icons/leftArrowGalleryIcon.svg');
  }

  .control-arrow.control-next:hover {
    background-image: url('../icons/rightArrowGalleryIcon.svg');
  }

  .control-dots,
  .carousel-status {
    display: none;
  }

  .carousel {
    position: relative;
  }

  .carousel .thumbs-wrapper {
    position: relative;
    top: -75px;
    margin-left: 0;

    .control-arrow.control-prev,
    .control-arrow.control-next {
      display: none;
    }
  }

  
  .carousel .thumb {
    width: 35px !important;
    border: none;
    margin-right: 20px;

    img {
      border: 1px solid $white;
    }
  }
}

@media (min-width: 992px) and (max-width: 1245px) {
  .carousel-root {
    max-width: calc(100% - 340px);
 }
}
.overview-menu-container {
  height: auto;
  width: 100%;
  display: block;
  background-color: $light-gray;

  .title {
    height: 72px;
    border-bottom: 1px solid $light-blue;
    padding: 20px 138.5px;
    text-align: center;
  }

  .product-description,
  .product-price {
    margin: 0 45px;
    padding: 20px 0;
  }

  .product-description {
    border-bottom: 1px solid $light-blue;

    div {
      display: inline-block;
    }

    .description {
      max-width: 172px;
      width: 100%;
      font-size: 18px;
      line-height: 32px;
      font-family: "DIN W01 Medium", sans-serif;
    }

    .picture {
      max-width: 133px;
      width: 100%;
      height: 100%;
      max-height: 80px;
    }
  }

  .product-info {
    border-bottom: 1px solid $light-blue;
    max-height: 310px;
    overflow: auto;
    margin: 0 45px;
    padding: 0 3px 20px 0;
    
    .info-item {
      padding-top: 20px;

      .price {
        font-size: 18px;
        line-height: 32px;
        font-family: "DIN W01 Regular", sans-serif;
        color: $dark-gray;
      }
    }
  }

  .product-price {
    .info-item {
      padding-bottom: 20px;

      input {
        width: 50px;
        height: 32px;
        text-align: center;
      }

      .total-price {
        font-weight: bold;
        cursor: help;
      }

      .price-per-unit {
        cursor: help;
      }
    }
  }

  .overview-menu__button-wrapper {
    text-align: center;
    position: absolute;
    bottom: -100px;
    right: 0;
    left: 0;
    margin: auto;
    padding: 10px;


    div {
      display: inline-block;
    }

    button {
      width: 138px;

      &.new-configuration {
        width: 100%;
      }

      a {
        font-size: 18px;
        line-height: 32px;
      }
    }

    .next-btn {
      margin-left: 30px;
      a {
        svg {
          float: right;
        }
      }
    }
    
    .back-btn {
      a {
        svg {
          float: left;
        }
      }
    }
  }

  .selected-equipment-list,
  .selected-accessories-list {
    width: 100%;
    overflow-y: auto;

    .info-list-item {
      list-style-type: none;
      padding: 0;

      li {
        padding-top: 20px;

        // &:first-child {
        //   padding-top: 0;
        // }  

        span {
          word-break: break-word;
          max-width: 180px;
          overflow: hidden;
          display: inline-flex;
          margin-top: 2px;
        }

        .price {
          color: $dark-gray;
        }
      }
    }
  }

  .close-btn {
    border: none;
    padding-right: 15px;
    padding-left: 0;
    vertical-align: top;
    background: $light-gray;

    &:focus {
      outline: none;
    }
  }

  .configuration-id {
    margin: 40px 45px 20px;

    h2 {
      color: $dark-gray;
    }

    .id-config {
      max-width: 307px;
      width: 100%;
      height: 60px;
      padding: 14px 62px;
      background: $white;
      border: 1px solid $black;
      font-size: 25px;
      line-height: 32px;
      font-family: "DIN W01 Regular", sans-serif;

      &:focus {
        outline: none;
      }
    }

    .save-btn {
      padding-top: 40px;

      .email-btn,
      .pdf-btn {
        display: inline-block;
        
        a {
          font-size: 15px;
          line-height: 24px;
        }

      }
      
      .email-btn {
        width: 142px;

        .rounded--button {
          height: auto;
        }
      }

      .pdf-btn {
        width: 136px;

        .rounded--button {
          height: auto;
        }
        
        a {
          svg {
            margin-left: 15px;
            margin-top: -2px;
          }
        }
      }
    }
  }
}

.input-error {
  font-size: 12px;
  line-height: 13px;
  color: #ad4444;
  margin-bottom: -5px;
  display: block;
  margin-top: 10px;
  text-align: center;
}

.custom-tooltip {
  cursor: help;

  .custom-tooltip-inner {
    max-width: 380px; 
  }
}

@include media-breakpoint-up(lg) {
  .overview-menu-container {
    height: 800px;
    max-width: 397px;
    width: 100%;
    margin-top: -72px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;

    .overview-menu__button-wrapper {
      bottom: 40px;
      left: 45px;
      right: 45px;
      padding: 0;
    }
      
    .configuration-id {
      .save-btn {
        .pdf-btn {
          margin-left: 29px;
        }
      }
    }
  }
}


.model-selection__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &.full-height {
      .model-selection__wrap {
        &.landscape {
          height: auto;
          padding: 10px;
        }
      }
    }
  
    .model-selection__wrap {
      padding: 0;
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid $light-gray;
      border-bottom: 1px solid $light-gray;
  
      &.portrait {
        height: auto;
      }
  
      &.landscape {
        height: auto;

        img {
          max-height: 100%;
        }
      }
  
      .model-selection__container {
        h2 {
          text-align: center;
        }
  
        .model-selection__image {
          margin-top: 20px;
  
          &.portrait {
            width: 237px;
            height: 342px;
          }
  
          &.landscape {
            width: 383px;
            height: 205px;
          }
        }
  
        .model-selection__btn {
          &.portrait {
            margin: -25px 53px 0;
          }
  
          &.landscape {
            margin: -25px 125px 0;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .model-selection__list {
  
      &.full-height {
        .model-selection__wrap {
          &.landscape {
            height: 728px;
            padding: 0;
          }
        }
      }
    
      .model-selection__wrap {
        &.portrait {
          height: 728px;
        }
    
        &.landscape {
          height: 364px;
  
        }
      }
    }
  }
.accessories-picker {
  margin-top: 20px;

  h2 {
    margin: 0;
    padding: 0;
    display: inline-block;
  }

  ul {
    list-style-type: none;
    display: inline-block;

    &.mechanical-accesories-category-list {
      max-width: 743px;
      padding: 0;
      margin: 0;

      li {
        padding-left: 50px;
        display: inline-block;
  
        .mechanical-accesories-category-item {
          text-transform: uppercase;
          border: none;
          background-color: $white;
          color: $mid-gray;
          
          //ako je botun pritisnut
          &.active {
            color: $black;
          }
  
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .mechanical-accesories {
    width: 608px;
    height: 85px;
    margin: 20px 0 0 68px;
    white-space: nowrap;
    overflow-x: auto;

    .accessory-item {
      display: inline-block;
      position: relative;
      width: 138px;

      img {
        width: 60px;
        height: 60px;
        margin: 0;
      }

      button {
        width: 76px;
        height: 32px;
        font-size: 14px;
        line-height: 24px;
        border-radius: 16px;
        background-color: $black;
        position: absolute;
        top: 0;
        right: 20px;
        border: none;
        opacity: 1;

        a {
          svg {
            margin-left: 5px;
          }
        }
      }

      .button-active {
        background: $light-gray;
        width: 85px;
        right: 10px;

        a {
          color: $black;
        }

        svg {
          display: none;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .accessories-picker {  
    .mechanical-accesories {
      width: 100%;
      margin: 0;
    }
  }
}
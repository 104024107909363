// varijable, mixini i ostalo sta cemo koristit vise puta
@import 'settings/variable';
@import 'settings/mixins';

// Import vendors
@import '~bootstrap/scss/bootstrap.scss';
@import '~react-responsive-carousel/lib/styles/carousel.css'; // requires a loader
@import '~react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

//globalni index
@import 'base/index.scss';

// components
@import 'components/buttons.scss';
@import 'components/header.scss';
@import 'components/overviewMenu.scss';
@import 'components/gallery.scss';
@import 'components/colorPicker.scss';
@import 'components/accessories.scss';
@import 'components/equipment.scss';
@import 'components/technicalData.scss';
@import 'components/modal.scss';

// containers
@import 'containers/homepage.scss';
@import 'containers/configurator.scss';
@import 'containers/summary.scss';

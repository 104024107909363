.equipment-picker {
  height: auto;
  width: 100%;

  ul {
    height: auto;
    overflow-x: auto;
    list-style-type: none;
    padding-left: 0;

    li {
      border-top: 1px solid $light-gray;
      width: 100%;
      min-height: 140px;
      height: 100%;
      padding: 20px 0;

      &:last-child {
        border-bottom: 1px solid $light-gray;
      }

      div {
        display: inline-block;
      }

      .modul-info {
        width: 100%;

        .modul-description {
          color: $dark-gray;
        }
      }

      .modul-btn {
        display: block;
        text-align: end;

        button {
          font-size: 14px;
          width: 76px;
          height: 32px;

          svg {
            margin-left: 4px;
          }
        }

        .button-active {
          background: $light-gray;
          border: none;
          width: 85px;
          right: 10px;

          a {
            color: $black;
          }

          svg {
            display: none;
          }
        }
      }

      .modul-picture {
        display: inline;
        padding-right: 20px;

        .main-image {
          vertical-align: top;
          display: inline-block;
          height: 100px;
          width: 100px;
        }
      }
    }
  }
}

.sub-title {
  max-width: 750px;
  padding: 10px 0;
}

@include media-breakpoint-up(lg) {
  .equipment-picker {
    height: 500px;
    width: 813px;
  
    ul {
      max-height: 650px;
  
      li {
        width: 743px;
  
        .modul-info {
          max-width: 531px;
          width: 100%;
        }

        .modul-btn {
          float: right;
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1245px) {

  .equipment-picker {
    width: calc(100% - 328px);

    ul li {
      max-width: 100%;
  
      .modul-btn {
        float: none;
      }
    }
  }
}
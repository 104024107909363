//generalno css za cili sajt kad dobijemo osnovni dizajn
@font-face {
  font-family: "DIN W01 Regular";
  src:
    url("../fonts/5591097/6ceed230-b2b3-4422-b048-4aa11687430a.woff2") format("woff2"),
    url("../fonts/5591097/80b0143f-6f0d-4dce-aafd-f3c81b85d177.woff") format("woff");
}

@font-face {
  font-family: "DIN W01 Medium";
  src:
    url("../fonts/5591111/398e3e8c-3bf0-4af1-9791-f426a7992711.woff2") format("woff2"),
    url("../fonts/5591111/4ba8e512-e6fb-494f-afd3-a7b68b2e5efb.woff") format("woff");
}

body {
  font-family: "DIN W01 Regular", sans-serif;
  margin: 0;
  padding: 0 !important;
  background-color: $white;
}

.site-container {
  width: 100%;
  margin: 0 auto;
  height: auto;
}

.container {
  background-color: $white;
  width: 100%;
  height: auto;
  position: relative;

  .row {
    padding: 0;
    margin: 0;
  }

  &.homepage {
    padding: 0;
  }
}

//globalne korisne klase
.right {
  float: right;
}

.left {
  float: left;
}

.clear { 
  clear: both; 
}

.hidden {
  display: none;
}

// osnovni elementi

ul, ol {
  // osnovni style za sve liste
  padding-left: 15px;
  margin-bottom: 0;
}

a {
  color: $black;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $black;
  }
}

img {
  width: 100%;
}

// naslovi
h1 { 
  font-size: 40px;
  line-height: 50px;
  padding-top: 20px;
} 
     
h2 { 
  font-size: 18px;
  line-height: 32px; 
} 
     
h3 { 
  font-size: 15px;
  line-height: 24px;
  font-family: "DIN W01 Medium", sans-serif;
} 
     
p { 
  font-size: 18px;
  line-height: 32px;      
}

// loading spinner
.load {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  z-index: 99999;

  .load__icon-wrap {
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    transform: rotate(3deg);
    transform: rotate(0.3rad);
    transform: rotate(3grad); 
    transform: rotate(.03turn);

    svg {
      width: 70px;
      height: 70px;
    }
  }
}

//custom-scrollbar
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $light-gray; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: $mid-gray; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $mid-gray; 
}

//breakponit for large screen
@include media-breakpoint-up(lg) {

  body {
    background-color: $mid-gray;
  }
  .site-container {
    max-width: 1280px;
    min-height: 728px;
  }

  .container {
    max-width: 1280px;
    padding: 0 70px;
  }
}


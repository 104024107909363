.header-container {
  background-color: $white;
  height: auto;
  border-bottom: 1px solid $light-gray;
  padding: 0 70px;
}

.header-main {
  height: auto;
  width: 100%;
  padding: 16px 0;
  text-align: center;
}

.active {
  color: $black;
}

.load-configuration {

  .btn-configuration {
    border: none;
      background-color: $white;
      
      &:focus {
        outline: none;
      }
  }
}

.product-category {
  color: $mid-gray;

  h2 {
    button {
      border: none;
      background-color: $white;
      
      &:focus {
        outline: none;
      }
    }
  }

  .product {
    &.active {
      border-bottom: 2px solid $black;
      color: $black;
    }
  }
}

.header-configurator {
  height: auto;
  width: 100%;
  padding: 20px 0;

  .row {
    display: block;
    width: 100%;
    text-align: center;
    margin: 0;
  }

  .header-configurator-wrapper {
    display: block;
    color: $mid-gray;

    h2 {
      display: block;
    }

    .arrow-icon {
      display: none;
    }
  }
}

// breakpoint for large screen
@include media-breakpoint-up(lg) {
  .header-container {
    height: 72px;
    font-size: 20px;
  }
  
  .header-main {
    padding: 20px 0;

    .row {
      display: inline-flex;
      margin: 0;
      width: 100%;
    }

    .base-model {
      h2 {
        display: inline-block;
        padding-left: 38px;
      }
    }

    .load-configuration {
      text-align: right;
    }

    .product-category {
      display: inline-block;
      text-align: center;

      h2 {
        display: inline-block;
        margin-right: 40px;
        margin-bottom: 0;
      }
    }
  }

  .header-configurator {
    max-height: 72px;
    height: 100%;
    max-width: 883px;

    .row {
      display: inline-flex;
      margin: 0;
      width: 100%;
      max-width: 1280px;
    }

    .header-configurator-wrapper {
      display: inline-block;
      text-align: left;

      h2 {
        display: inline-block;
        margin: 0;
      }

      .look-label {
        padding-left: 38px;
      }

      .arrow-icon {
        padding: 0 10px;
        font-size: 21px;
        display: inline-block;
      }
    }
  }
}
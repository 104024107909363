.modal {
  top: 25%;

  .modal__header.fixed-header {
    position: fixed;
    background: $white;
    z-index: 1;
    overflow: hidden;
    max-width: 574px;
    width: 100%;
    padding-bottom: 20px;

    @include media-breakpoint-down(xs) {
      max-width: calc(100vw - 30px);
    }
  }
}

.modal-content {
  width: 100%;
  height: auto;
  padding: 10px 0;
}

.modal-dialog {
  max-width: 586px;
}

.close-modal-btn {
  width: 16px;
  height: 16px;
  border: none;
  background-color: $white;
  padding: 20px;

  svg {
    width: 16px;
    height: 16px;
  }

  &:focus {
    outline: none;
  }
}

.modal__body,
.modal__title {
  padding: 0 40px;
}

.modal__title {
  font-size: 30px;
  line-height: 25px;
  margin: 0;
}

.modal__body {
  font-size: 18px;
  line-height: 32px;
  padding-bottom: 20px;

  &.scrollable-body {
    margin-top: 140px;
  }

  .email-text {
    padding-top: 40px;
    margin: 0;
  }

  .btn-email-wrap {
    float: right;
    margin-top: 40px;
  }

  .email-input {
    width: 95%;    
    height: 60px;
    background: $light-gray;
    border: 1px solid $dark-gray;
    box-sizing: border-box;
    padding: 14px;
    margin-top: 10px;

    &.active {
      background: $white;
      border: 1px solid $black;
    }

    &:focus {
      outline: none;
    }

  }

  .checkbox-news {
    width: 24px;
    height: 24px;
    margin-top: 40px;
    background: $white;
    border: 1px solid  $dark-gray;
    box-sizing: border-box;
    margin-bottom: 0;
  }

  .checkbox-text {
    font-size: 15px;
    line-height: 24px;
    font-family: "DIN W01 Medium", sans-serif;
    width: 100%;
    color: $dark-gray;
    display: inline;
    vertical-align: super;
    padding-left: 10px;
    margin-top: -5px;

    &.active {
      color: $black;
    }
  }

  .help-link {
    padding-top: 40px;

    .link-text {
      font-size: 15px;
      line-height: 24px;
      font-family: "DIN W01 Medium", sans-serif;
      display: inline-block;
      padding-top: 15px;
    }

    .link-btn {
      display: inline-block;
    }
  }

  .modal__input-error {
    font-size: 12px;
    line-height: 13px;
    color: #ad4444;
    margin-bottom: -5px;
    display: block;
    margin-top: 10px;
  }
  
  .alert {
    margin-top: 10px;
  }
}

.email-modal {
  padding: 80px;
}

.modules-info .tech-list__icon {
  margin: 0 !important;
}

.tech-list,
.modules-info {
  list-style: none;
  padding-left: 5px;

  li {
    border-top: 1px solid $light-gray;
    padding: 10px 0;

    &:last-child {
      border-bottom: 1px solid $light-gray;
    }
  }

  .tech-list__key,
  .tech-list__value {
    display: inline-block;
    vertical-align: text-top;
    margin-top: -5px;
  }

  .tech-list__icon {
    height: 30px;
    width: 30px;
    display: inline-block;
    margin: 10px 10px -10px 0px;
  }

  .tech-list__key {
    text-transform: capitalize;
  }
}

// checkbox
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: 0;
  opacity: 0.01;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  cursor: pointer;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  background: $white;
  border: 1px solid $dark-gray;
  box-sizing: border-box;
  left: -1px;
  top: -1px;
}

/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: url('../icons/checkIcon.svg');;
  position: absolute;
  top: .28em;
  left: .18em;
  font-size: 1.375em;
  line-height: 0;
  -webkit-transition: all .2s;
    transition: all .2s;
}

/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
}

[type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
}

.modal-dialog-scrollable .modal-content {
  max-height: 60vh;
  overflow-y: scroll;
}

// breakpoint for large screen
@include media-breakpoint-up(lg) {
  .modal {
    top: calc(25% - 80px);
    left: calc(5% - 80px);
  }
  
  .modal-content {
    width: 586px;
    padding: 0;
    min-height: 495px;
  }

  .close-modal-btn {
    width: 32px;
    height: 32px;
    padding: 40px;
  
    svg {
      width: 32px;
      height: 32px;
    }
  }
  
  .modal__body,
  .modal__title {
    padding: 0 80px;
  }
  
  .modal__title {
    font-size: 40px;
    line-height: 50px;

    &.email-send {
      margin-top: 80px;
    }
  }
  
  .modal__body {
    font-size: 18px;
    line-height: 32px;

    h4 {
      font-family: "DIN W01 Medium";
      padding-top: 20px;
    }

    &.email-send {
      .email-text {
        padding-top: 20px;
      }

      .btn-email {
        margin-top: 40px;

        a {
          background: $white;
          color: $black;
          border-radius: 40px;
        }
      }
    }
  
    .email-text {
      padding-top: 40px;
    }
  
    .email-input {
      width: 426px;
    }
  
    .checkbox-text {
      width: 270px;
      padding-left: 10px;
      margin-top: -5px;
      display: inline-block;
      vertical-align: text-top;
    }
  
    .help-link {
      padding-top: 40px;
  
      .link-text {
        font-size: 15px;
        line-height: 24px;
        font-family: "DIN W01 Medium", sans-serif;
        display: inline-block;
        padding-top: 15px;
      }
  
      .link-btn {
        display: inline-block;
      }
    }
  }
  
  .email-modal {
    padding: 80px;
  }

  .modal-dialog-scrollable .modal-content {
    max-height: 600px;
  }
}

.required-modal {
  .modal-content {
    min-height: auto;
  }
}

.modal-ok-btn {
  text-align: center;
  margin-bottom: 50px;
}

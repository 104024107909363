$color-accent: #4ce1b6;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #ff4861;
$color-red-hover: darken($color-red, 10%);

$color-blue: #70bbfd;
$color-blue-hover: darken($color-blue, 10%);
$color-royal-blue: #1a6ac4;

$color-green: #b8e986;
$color-green-hover: darken($color-green, 10%);
$color-dark-green: #236b07;

$color-lavender: #E6E6FA;
$color-silver: #C0C0C0;
$color-light-steel-blue: #B0C4DE;
$color-white: #FFFFFF;
$color-purple: #776dc0;

// Boje iz dizajna
$light-gray: #eef0f1;
$mid-gray: #bdc4c9;
$dark-gray: #747a7d;
$light-blue: #DDE0E2;
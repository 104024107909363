.rounded--button {
  font-family: "DIN W01 Medium", sans-serif;
  height: 52px;
  border-radius: 26px;
  padding: 0;
  background-color: $black;
  display: inline-block;

  &:hover {
    cursor: pointer;
  }

  &.btn,
  &.btn-secondary {
    &:hover,
    &:active,
    &:focus {
      outline: none;
      color: $white;
      background-color: $black;
      border-color: $black;
    }
  }


  a {
    display: block;
    height: 100%;
    width: 100%;
    padding: 14px 22px;
    text-transform: uppercase;
    line-height: 24px;
    color: $white;

    &:hover {
    color: $white;
    }
  }

  //bijela pozadina
  &.light {
    background-color: $white;
    border: 1px solid #000;

    a {
      color: $black;
    }

    &.btn,
    &.btn-secondary {
      &:hover,
      &:active,
      &:focus {
        outline: none;
        color: $black;
        background-color:  $white;
        border-color:  $black;
      }
    }
  }

  //siva pozadina
  &.gray {
    background-color: $mid-gray;
    border: none;

    a {
      color: $white;
    }

    &.btn,
    &.btn-secondary {
      &:hover,
      &:active,
      &:focus {
        outline: none;
        color: $white;
        background-color: $mid-gray;
        border-color: $mid-gray;
      }
    }
  }

  &.disabled {
    pointer-events: none;
  }
}
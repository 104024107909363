.info-model-summary {
  margin-top: -35px;
  div {
    max-width: 752px;
    margin-left: 0;
    padding: 0;
    display: flex;
  }

  img {
    height: 40px;
    width: 40px;
    margin-right: 20px;
    align-content: space-between;
    flex: auto;
  }
}

.modal-links {
  margin-top: 40px !important;
  text-align: center;

  button {
    margin-top: 20px;
    font-size: 15px;
    line-height: 24px;
    font-family: "DIN W01 Medium", sans-serif;
    background: none;
    border: none;
    padding: 0;
    margin: 0;

    &:focus {
      outline: none;
    }
  }
}

.modules-info {
  margin-top: 5px;

  span {
    padding-left: 15px;
    color: $dark-gray;
    font-size: 15px;
    line-height: 24px;
    font-family: "DIN W01 Medium", sans-serif;
  }

  img {
    height: 35px;
    width: 35px;
  }
}

.tech-data {
 height: 75px;
 overflow: hidden;
}

@include media-breakpoint-down(sm) {
  .tech-data {
    max-width: 150px;
  }

  .tech-links {
    max-width: 200px;
  }

  .info-model-summary {
    div {
      display: block;
    }

    img {
      margin-top: 5px;
    }
  }
}
